<template>
	<v-container>
		<v-layout column wrap>
			<v-card>
				<v-card-title class="text-capitalize">{{ "settings" | trans }}</v-card-title>
				<v-card-text>
					<!-- POS -->
					<div v-module="'punto'" v-can="'Punto de Venta'">
						<v-subheader>{{ "point of sale" | trans }}</v-subheader>
						<item-selector
							prepend-icon="mdi-warehouse"
							resource="bodegas"
							v-model="settings.pos_bodega_id"
						></item-selector>
						<v-text-field
							prepend-icon="mdi-printer-pos"
							label="Prefijo Facturacion POS"
							v-model="settings.pos_invoice_prefix"
						></v-text-field>
						<v-text-field
							prepend-icon="mdi-book"
							label="Número Facturación POS"
							type="number"
							v-model.number="settings.pos_invoice_number"
							min="0"
						></v-text-field>
						<v-textarea
							rows="2"
							prepend-icon="mdi-printer-pos"
							label="Resolucion de  Facturacion POS"
							v-model="settings.pos_invoice_resolucion"
						></v-textarea>
						<v-textarea
							rows="2"
							prepend-icon="mdi-printer-pos"
							label="Cabecera de Facturacion POS"
							v-model="settings.pos_invoice_cabecera"
						></v-textarea>
						<v-textarea
							rows="2"
							prepend-icon="mdi-printer-pos"
							label="Pie de Pagina de Facturacion POS"
							v-model="settings.pos_invoice_pie"
						></v-textarea>
					</div>

					<v-subheader>{{ "theme" | trans }}</v-subheader>
					<!-- Color Selector -->
					<v-select
						prepend-icon="palette"
						:label="'color' | trans"
						:items="colors"
						color="primary"
						v-model="settings.color"
					>
						<template v-slot:item="{ item }">
							<v-list-item-content>
								<v-list-item-title class="text-capitalize" v-text="item.text"></v-list-item-title>
								<v-list-item-subtitle v-text="item.value"></v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action>
								<div style="height: 24px; width: 24px; border-radius: 100%" :style="{ background: item.value }"></div>
							</v-list-item-action>
						</template>
					</v-select>

					<!-- Dark Mode -->
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title>Tema Oscuro</v-list-item-title>
						</v-list-item-content>
						<v-list-item-action>
							<v-switch v-model="$vuetify.theme.dark"></v-switch>
						</v-list-item-action>
					</v-list-item>
				</v-card-text>
				<div style="height: 10px"></div>
			</v-card>
		</v-layout>

		<v-btn @click="save()" color="primary" depressed fab fixed bottom right>
			<v-icon>save</v-icon>
		</v-btn>
	</v-container>
</template>
<script>
import colors from "vuetify/lib/util/colors";
export default {
	data() {
		return {
			colors: [
				{ text: "blue", value: colors.blue.base },
				{ text: "red", value: colors.red.base },
				{ text: "pink", value: colors.pink.base },
				{ text: "purple", value: colors.purple.base },
				{ text: "deepPurple", value: colors.deepPurple.base },
				{ text: "indigo", value: colors.indigo.base },
				{ text: "lightBlue", value: colors.lightBlue.base },
				{ text: "cyan", value: colors.cyan.base },
				{ text: "teal", value: colors.teal.base },
				{ text: "green", value: colors.green.base },
				{ text: "lightGreen", value: colors.lightGreen.base },
				{ text: "lime", value: colors.lime.base },
				{ text: "yellow", value: colors.yellow.base },
				{ text: "amber", value: colors.amber.base },
				{ text: "orange", value: colors.orange.base },
				{ text: "deepOrange", value: colors.deepOrange.base },
				{ text: "brown", value: colors.brown.base },
				{ text: "grey", value: colors.grey.base },
				{ text: "blueGrey", value: colors.blueGrey.base },
			],
		};
	},
	methods: {
		save() {
			this.saveSettings();
			this.api.storage.set("themeDark", this.$vuetify.theme.dark);
			this.api.successful("Guardado!");
			if (this.settings.color) {
				this.$vuetify.theme.themes.light.primary = this.settings.color;
				this.$vuetify.theme.themes.dark.primary = this.settings.color;
				document.querySelector("meta[name=theme-color]").setAttribute("content", this.settings.color);
			}
		},
	},
};
</script>
